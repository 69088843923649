@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$product-cta-background-color: $button-primary-background-color !default;
$product-cta-border: 1px solid $button-primary-background-color !default;
$product-cta-button-radius: none !default;
$product-cta-color: white !default;

.product-card {
	$this: &;

	&__picture {
		position: relative;
		height: 192px;
		cursor: pointer;
		border-radius: $border-radius-big;
		overflow: hidden;

		.progressive-img {
			display: none;
			border-radius: $border-radius-big;

			img {
				border-radius: $border-radius-big;
			}
		}

		.background-progressive-image {
			display: block;
			border-radius: $border-radius-big;

			&__image {
				border-radius: $border-radius-big;
			}
		}

		// Pourvoir avoir le click droit ouvrir nouvel onglet depuis la photo du produit
		.relative-link {
			display: block;
			padding: 0;
			height: 100%;
			width: 100%;
			background: none;
			box-shadow: none;

			&__text {
				display: block;
				height: 100%;
				width: 100%;
			}
		}

		@include gridle_state(mc) {
			height: 240px;
		}

		@include gridle_state(sm) {
			height: 350px;
		}

		@include gridle_state(md) {
			height: 282px;

			.progressive-img {
				display: block;
			}

			.background-progressive-image {
				display: none;
			}
		}

		@include gridle_state(md) {
			&:hover {
				.images-carousel-pp {
					&__arrow {
						opacity: 1;
						transition: opacity 0.5s ease, transform 0.35s ease;
						transform: translateX(0);
					}
				}
			}
		}

		.images-carousel-pp {
			&__arrow {
				top: 42%;
			}

			.carousel-dots {
				top: calc(100% - 16px);

				@include gridle_state(md) {
					top: calc(100% - 24px);
				}
			}
		}
	}

	&__topbar {
		position: absolute;
		top: 8px;
		right: 0;
		left: 8px;
		pointer-events: none; // sinon ca bloque le click sur la fiche produit
	}

	&__footer {
		> .relative-link {
			display: block;
			background: white; // eviter de prendre la couleur de RelativeLink
			color: inherit; // eviter de prendre la couleur de RelativeLink
			font-family: inherit; // eviter de prendre la font de RelativeLink
			font-weight: inherit; // eviter de prendre la font-weight de RelativeLink
			text-align: left; // eviter de prendre le text-align de RelativeLink
			text-transform: none; // eviter de prendre le text-align de RelativeLink
			border-radius: 0 0 $border-radius-big $border-radius-big;
			padding: 10px 8px 8px;
			box-shadow: none;

			.relative-link__text {
				display: block;
				width: 100%;
			}
		}
	}

	.product-destination-title {
		display: inline;
		font-size: inherit; // can be removed if ProductCard win in abtest
		line-height: 1.4; // can be removed if ProductCard win in abtest

		&__region {
			text-transform: none;
		}

		@include gridle_state(md) {
			display: flex; // crop to work on desktop
		}
	}

	.product-name-with-category {
		margin-top: 0;
		color: $black; // can be removed if ProductCard win in abtest
		font-size: inherit; // can be removed if ProductCard win in abtest
		line-height: 1.4; // can be removed if ProductCard win in abtest

		@include gridle_state(md) {
			margin-top: 2px;
		}
	}

	.product-rating {
		margin-top: 6px;
	}

	&__info {
		&--right {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-top: 12px;
		}
	}

	.tripadvisor {
		pointer-events: none; // disable click on tripadvisor for product card

		&__reviews {
			text-decoration: none;

			.icon {
				display: none;
			}
		}
	}

	&__action {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background-color: $product-cta-background-color;
		color: $product-cta-color;
		border: $product-cta-border;
		border-radius: $product-cta-button-radius;
		box-sizing: border-box;
		text-transform: none; // TODO a supprimer quand cette variation gagne et que BadgeList aura le new design

		&__text {
			color: $product-cta-color;
		}

		@include gridle_state(md) {
			width: auto;
			min-height: 40px;
			padding: 12px 24px; // TODO 24px à enlever quand on aura le bouton du design system
		}
	}

	&__badges {
		margin-top: 12px;
		border-top: 1px solid var(--color-gray-400, $grey-light);
		padding-top: 4px;
		font-size: var(--font-size-regular);
	}

	&__badge-separator {
		font-size: 14px;
		padding-right: 5px;
		padding-left: 5px;
		color: $grey-light;
	}

	&--new-price-right {
		@include gridle_state(md) {
			#{$this}__container {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				gap: 16px;
			}

			#{$this}__info {
				&--left {
					flex: 1 1 0%;
					min-width: 0; // for text ellipsis to work @see https://css-tricks.com/flexbox-truncated-text/
				}

				&--right {
					flex-direction: column;
					margin-top: 0;

					.product-card-pricing {
						text-align: right;
					}
				}
			}

			.product-destination-title {
				display: inline;

				@include gridle_state(md) {
					display: inline;
				}
			}

			#{$this}__action {
				margin-top: 16px;
			}
		}
	}

	&--crop-name {
		.product-name-with-category {
			@include gridle_state(md) {
				&__name {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}

	&--crop-destination {
		.product-destination-title {
			@include gridle_state(md) {
				display: block;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				&__resort {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}

	@include gridle_state(md) {
		border-radius: $border-radius-big;
		transition: box-shadow 0.6s ease;
		box-shadow: none;

		&:hover {
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		}
	}
}
