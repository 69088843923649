@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
@import "~swiper/src/swiper.scss";

.products-carousel {
	position: relative;

	&__button {
		width: 50px;
		height: 50px;
		opacity: 0.7;
		color: $black;
		cursor: pointer;
		border-radius: 100%;
		background: white no-repeat;
		position: absolute;
		top: 30%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;

		&--previous {
			left: 15px;
		}

		&--next {
			right: 15px;
		}

		@include gridle_state(md) {
			&:hover,
			&:focus {
				box-shadow: none;
			}
		}

		.swiper-wrapper {
			margin-bottom: 3px !important; // pour afficher la totalite du box shadow
		}
	}

	&__list {
		display: flex;
		justify-content: space-between;
	}

	&__item {
		min-height: 380px;
		margin-right: 17px;

		&:last-of-type {
			margin-right: 0;
		}

		.product-top-bar .badge {
			padding-right: 10px;
			padding-left: 10px;
		}

		@include gridle_state(md) {
			margin-right: 20px;
		}

		.product-with-translucent-deal {
			&__picture {
				height: 212px;

				.images-carousel-pp__image {
					height: 212px;
				}
			}

			&__footer {
				min-height: 185px;
			}

			&__deal .product-deal {
				width: 100%;
			}
		}
	}

	.swiper-wrapper {
		padding-bottom: 4px;
	}
}
