@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.progressive-image {
	position: absolute;
	top: 0;
	transition: opacity 1s ease;
	opacity: 0;

	&--revealed {
		opacity: 1;
	}
}
