@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.product-name-with-category {
	unicode-bidi: plaintext;

	// force rtl for product name
	// @see https://github.com/elchininet/postcss-rtlcss?tab=readme-ov-file#rtlrawcss

	/*rtl:raw:
	   direction: ltr;
	   justify-content: flex-end;*/
}
