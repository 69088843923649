@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.reason-to-book-block {
	padding: 24px 0 24px 16px;

	&__title {
		margin-right: 16px;
		margin-bottom: 32px;
	}

	@include gridle_state(md) {
		padding: 32px 0;

		&__title {
			margin-right: 0;
			margin-bottom: 24px;
		}
	}
}
