@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.floating-button {
	cursor: pointer;

	&__content {
		color: $grey-dark;
		background-color: white;
		border: 1px solid $grey-medium;
		border-radius: 3px;
		padding: 14px;
		box-sizing: border-box;
		box-shadow: -15px 23px 47px -26px rgba(0, 0, 0, 0.67);
		max-width: 95%;
		margin-right: auto;
		margin-left: auto;

		a {
			color: $grey-dark;
		}

		.list {
			margin-top: 10px;
		}

		@include gridle_state(md) {
			max-width: 400px;
		}
	}

	&__arrow {
		position: absolute;
		border: 1px solid $grey-medium;
		background: white;
		width: 8px;
		height: 8px;
		transform: rotate(45deg);
	}
}
